<template>
  <div class="login" style="background: linear-gradient(to right, #2775ff 50%, #fff 50%);">
    <div class="login-info" >
      <Card style="padding:20px;box-shadow: 0 1px 6px rgba(0, 0, 0, .2);border-color: #eee;">
        <div class="align-center">
          <span class="font-douyu main-color" style="font-size: 56px;">{{ brand_name }}</span>
        </div>

        <div style="margin-top:50px;">
          <Form ref="formInline" :model="formInline" :rules="ruleInline">
            <Form-item prop="mobile">
              <Input prefix="ios-person-outline" type="text" size="large" v-model="formInline.mobile" placeholder="请填写手机号">
              </Input>
            </Form-item>
            <Form-item prop="password">
              <Input prefix="ios-lock-outline" type="password" size="large" v-model="formInline.password" placeholder="请填写密码" @keyup.enter.native="enterToLogin('formInline')">
              </Input>
            </Form-item>
            <Form-item>
              <Button type="primary" size="large" @click="handleSubmit('formInline')" class="submit-button" :loading="login_loading">
                登录
              </Button>
            </Form-item>
          </Form>
        </div>

        <div class="align-center" style="display:flex;justify-content: space-between;">
<!--            <span class="forgot">-->
<!--              <router-link to="/forgot"><span>忘记密码？</span></router-link>-->
<!--            </span>-->
        </div>
      </Card>
    </div>

<!--    <div class="bg-desc pointer" :title="background.copy_right">-->
<!--      <Icon type="ios-pin"/>&nbsp;-->
<!--      <span>{{ background.img_title }}</span>-->
<!--    </div>-->
  </div>


</template>

<script>
import * as loginUtil from '@/utils/login'
import * as bgImgUtil from '@/utils/login/backgroundImg'

export default {
  name: "Login",
  data() {
    return {
      formInline: {
        mobile: '',
        password: '',
      },
      login_loading: false,
      ruleInline: {
        mobile: [
          {required: true, message: '请填写手机号', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请填写密码', trigger: 'blur'},
          {type: 'string', min: 6, message: '密码长度不能小于6位', trigger: 'blur'}
        ]
      },
      brand_name: '禾晓',
      login_p_l: 0,
      login_p_t: 0,

      try_login_flag: false,
    };
  },
  methods: {
    handleSubmit(name) {
      this.login_loading = true;
      let param = {
        mobile: this.formInline.mobile,
        password: this.md5(this.formInline.password),
      };

      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$axios.post('/api/user/login', param).then(response => {
            if (response.data.err_no == 0) {
              localStorage.setItem('api_token', 'Bearer ' + response.data.results.token);
              localStorage.setItem('is_login', 1);
              loginUtil.setExpireTime();
              //跳转
              location.href = "/";
            } else {
              this.$Message.error(response.data.err_msg);
              this.login_loading = false;
            }
          }).catch(error => {
            this.$Message.error('登录异常，请联系客服！');
            this.login_loading = false;
          });
        } else {
          this.login_loading = false;
        }
      });
    },
    enterToLogin(name) {
      this.handleSubmit(name);
    },
    checkLoginStatus() {
      let api_token = localStorage.getItem('api_token');
      let is_login = localStorage.getItem('is_login');
      if (api_token != undefined && is_login != 0) {
        //跳转
        location.href = "/";
      }
    },
    setBrandName() {
      if (localStorage.getItem('brand_name') && localStorage.getItem('brand_name') != undefined) {
        this.brand_name = localStorage.getItem('brand_name');
      }
      ;
    },
    // getBackgroundImg() {
    //   bgImgUtil.getBackgroundImg().then(res => {
    //     this.background.img_url = res.img_url;
    //     this.background.img_title = res.img_title;
    //     this.background.copy_right = res.copy_right;
    //   })
    // }
  },
  created() {
    this.login_p_l = localStorage.getItem('login_p_l');
    this.login_p_t = localStorage.getItem('login_p_t');
    // this.getBackgroundImg();
  },
  mounted() {
    this.setBrandName();
    this.checkLoginStatus();
  }
};
</script>

<style scoped>
.login {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-info {
  position: absolute;
  width: 380px;
  height: 470px;
  top: calc(50% - 235px);
  left: calc(50% - 190px);
  cursor: pointer;
}

.bg-desc {
  position: absolute;
  background: rgba(0, 0, 0, .4);
  margin-bottom: 1rem;
  bottom: 0;
  right: 1.5rem;
  color: #fff;
  padding: 6px 10px;
  border-radius: 6px;
}

.submit-button {
  width: 100%;
}

.login_title {
  text-align: left;
  color: #657180;
}

.forgot, .forgot a {
  color: #657180;
}

.mgl60 {
  margin-left: 60px;
}

.ivu-form .login_title .ivu-form-item-label {
  font-size: 20px !important;
}

.mt-120 {
  margin-top: 120px;
}

.mt-80 {
  margin-top: 80px;
}

.ivu-form-item {
  margin-bottom: 40px;
}
</style>
